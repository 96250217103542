@use './styles/_mixins.scss' as *;
.categories {
  position: relative;
  display: grid;
  z-index: 2;
  gap: 32px;

  @media (min-width: 800px) {
    grid-auto-columns: 1fr 1fr;
    grid-template-columns: repeat(2, 1fr);
  }
  @include breakpoint(huge) {
    grid-template-columns: repeat(3, 1fr);
  }

  &_item {
    position: relative;
    display: flex;
    flex-flow: column;
    margin: auto;
    max-width: 500px;
    width: 100%;
    padding-bottom: 41px;

    // @media (min-width: 800px) {
    //   padding-bottom: 51px;
    //   &:nth-last-child(1):nth-child(odd) {
    //     grid-column: auto / span 2;
    //   }
    // }
    @include breakpoint(large) {
      max-width: 753px;
    }
    @include breakpoint(huge) {
      &:nth-last-child(1):nth-child(odd) {
        grid-column: unset;
      }
      &:nth-last-child(1):nth-child(even) {
        grid-column: 2 / 3;
      }
    }

    &:before {
      position: absolute;
      content: '';
      left: -8px;
      right: -8px;
      top: -10px;
      background: url('/assets/topCurve.svg') no-repeat;
      height: 50px;
      background-size: cover;
      z-index: -1;
    }
    &:after {
      position: absolute;
      content: '';
      left: -8px;
      right: -8px;
      bottom: 0;
      top: 39px;
      background: white;
      z-index: -1;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      @include breakpoint(large) {
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
      }
    }

    &:hover {
      .categories_item_content_inner {
        max-height: 155px;
      }

      @include breakpoint(large) {
        .categories_item_content_inner {
          max-height: 182px;
        }
      }

      .categories_item_buttons {
        visibility: visible;
      }
    }

    &_content {
      position: relative;
      background: white;
      padding: rem(8) 0 rem(8) 0;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      text-align: center;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;

      @include breakpoint(large) {
        padding: rem(16) 0 rem(16) 0;
      }

      @include breakpoint(large) {
        padding: rem(24) rem(8) rem(24) rem(8);
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
      }

      &_inner {
        overflow: hidden;
        max-height: 20px;
        transition: max-height 0.6s ease;
        @include breakpoint(large) {
          max-height: 24px;
        }
      }
    }

    &_title {
      font-size: rem(14);
      font-weight: var(--font-regular);
      text-transform: uppercase;
      letter-spacing: -0.8px;
      color: var(--color-secondary);

      @include breakpoint(small) {
        :global(.lang-es) & {
          font-size: rem(13);
        }
      }

      @include breakpoint(medium) {
        font-size: rem(16);
      }
      @include breakpoint(large) {
        font-size: rem(20);
      }
    }

    &_buttons {
      display: flex;
      justify-content: center;
      flex-flow: column;
      max-width: 300px;
      margin: auto;
      visibility: hidden;
      transition: visibility 0.6s ease-out;

      @include breakpoint(small) {
        :global(.lang-es) & {
          max-width: 100%;
        }
      }

      @include breakpoint(medium) {
        max-width: 390px;

        :global(.lang-es) & {
          max-width: 410px;
        }
      }

      a {
        &:first-child {
          margin-top: rem(8);
          @include breakpoint(medium) {
            margin-top: rem(16);
          }
        }
        &:nth-child(2) {
          margin-top: 10px;
        }
        span {
          padding: 10px 5px;
          text-transform: uppercase;
          font-size: 11px;

          @include breakpoint(small) {
            :global(.lang-es) & {
              letter-spacing: -0.4px;
            }
          }

          @include breakpoint(medium) {
            padding: 13px 16px;
            font-size: 12px;
          }
        }
      }
    }

    &_image {
      margin: 0 auto 0 auto;
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }
}
